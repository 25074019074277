<af-page-bar title="{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.wages' | translate }}">
</af-page-bar>

<af-page-filter [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.year' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.year">
            <option [ngValue]="null"></option>
            <option *ngFor="let year of filter.years" [ngValue]="year.key">{{year.value}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.month' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.month">
            <option [ngValue]="null"></option>
            <option *ngFor="let month of filter.months" [ngValue]="month.key">{{month.value}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.country' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.countryCode">
            <option *ngFor="let country of filter.countries" [ngValue]="country.code">{{country.title}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.account center' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.accountCenterCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.all' | translate }}</option>
            <option *ngFor="let accountCenter of filter.accountCenters" [ngValue]="accountCenter.code">{{accountCenter.title}}</option>
        </select>
    </div>
    <div class="form-group" *ngIf="queryParams.countryCode === 'H'">
        <af-checkbox [(ngModel)]="queryParams.includeCooperants" label="{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.include cooperants' | translate }}"></af-checkbox>
    </div>
</af-page-filter>

<a [href]="getReportUrl()" target="_blank" class="btn btn-primary" [class.disabled-all]="isLoading">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.generate' | translate }}</a>

<af-spinner #afSpinner [loading]="isLoading"></af-spinner>

<ng-container *ngIf="!isLoading">
    <div class="mt-2" *ngIf="model && model.items && model.items.length > 0">
        <div class="table-wrapper">
            <table class="af-table table table-striped table-bordered table-hover text-end mt-2" style="font-size: 10px">
                <thead>
                    <tr>
                        <th style="width: auto"></th>
                        <th>{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.date' | translate }}</th>
                        <th *ngFor="let item of model.items[0].items" style="width: 40px">
                            <div>{{ item.day }}</div>
                        </th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.hours' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.amount' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.night_weekend_weather' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.total' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.refund' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.total 2' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.type' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.other agreements' | translate }}</th>
                        <th *ngFor="let item of model.items[0].accountCenters" style="width: 40px">
                            <div>{{ item.accountCenterTitle }}</div>
                        </th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.night (hours)' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.weekend (hours)' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.holiday (hours)' | translate }}</th>
                        <th style="width: 50px">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.bms' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let wage of model.items; let i = index" (click)="onRowClick(i)" [class.table-primary]="i === selectedIndex" [class.table-warning]="wage.hasEmployeeRelationshipChanged">
                        <td>{{i+1}}.</td>
                        <td class="text-start"><a [routerLink]="['/operation/employee', wage.employeeId]">{{wage.employeeName}} <span *ngIf="wage.hasEmployeeRelationshipChanged">!</span></a></td>
                        <td *ngFor="let item of wage.items">
                            <div *ngIf="item.hours">{{ item.hours | number:'.2-2' }}</div>
                        </td>
                        <td>{{ wage.hours | number:'.2-2' }}</td>
                        <td>{{ wage.hourAmount | number:'.2-9' }}</td>
                        <td>{{ wage.bonuses | number:'.2-9' }}</td>
                        <td>{{ wage.amount | number:'.2-9' }}</td>
                        <td>{{ wage.refund | number:'.2-9' }}</td>
                        <td>{{ wage.totalAmount | number:'.2-9' }}</td>
                        <td>{{ wage.employeeContractType }}</td>
                        <td>{{ wage.otherAgreements | number:'.2-9' }}</td>
                        <td *ngFor="let item of wage.accountCenters" style="width: 40px">
                            <div>{{ item.amount | number:'.2-9' }}</div>
                        </td>
                        <td>{{ wage.total.totalNightHours | number:'.2-2' }}</td>
                        <td>{{ wage.total.totalSaturdayHours | number:'.2-2' }}</td>
                        <td>{{ wage.total.totalHolidayOrSundayHours | number:'.2-2' }}</td>
                        <td>{{ wage.bms | number:'.2-9' }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="text-start">{{ 'hollen.clientapp.src.app.b2b.wages.wages.component.total' | translate }}</td>
                        <td *ngFor="let item of model.items[0].items"></td>
                        <td>{{ model.hours | number:'.2-2' }}</td>
                        <td>{{ model.hourAmount | number:'.2-9' }}</td>
                        <td>{{ model.bonuses | number:'.2-9' }}</td>
                        <td>{{ model.amount | number:'.2-9' }}</td>
                        <td>{{ model.refund | number:'.2-9' }}</td>
                        <td>{{ model.totalAmount | number:'.2-9' }}</td>
                        <td></td>
                        <td>{{ model.otherAgreements | number:'.2-9' }}</td>
                        <td *ngFor="let item of model.totalAccountCenters" style="width: 40px">
                            <div>{{ item.amount | number:'.2-9' }}</div>
                        </td>
                        <td>{{ model.total.totalNightHours | number:'.2-2' }}</td>
                        <td>{{ model.total.totalSaturdayHours | number:'.2-2' }}</td>
                        <td>{{ model.total.totalHolidayOrSundayHours | number:'.2-2' }}</td>
                        <td>{{ model.bms | number:'.2-9' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
